import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import server from '../api/server';

// var stats = {
//     day_bot_creation: day_count.length,
//     three_day_last_active: bots_active_count,
//     three_day_last_active_percent: (bots_active_count / day_count.length) * 100 || 0,

//     three_day_user_creation: user_created_count,
//     three_day_user_logged: logged_count,
//     three_day_user_logged_percent: (logged_count / user_created_count) * 100 || 0,

//     usage_total: usage_total,
//     usage_average: usage_average,
//     usage_max: usage_max,

//     usage_total_basic: usage_total_basic,
//     usage_average_basic: usage_average_basic,
//     usage_max_basic: usage_max_basic,

//     usage_total_premium: usage_total_premium,
//     usage_average_premium: usage_average_premium,
//     usage_max_premium: usage_max_premium,

//     total_bots: total
// };


export class HomeCharts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statsArray: [],
            loaded: false,
            chartData: [
                { key: 'day_bot_creation', title: "Daily Bots Created", xaxis: [], series: [] },
                { key: 'server_count', title: "Total Online Servers", xaxis: [], series: [] },
                { key: 'three_day_last_active', title: "Bots Active after 3 Days", xaxis: [], series: [] },
                { key: 'three_day_last_active_percent', title: "Bots Active after 3 Days %", xaxis: [], series: [] },
                { key: 'three_day_user_creation', title: "Users Created -3 Days", xaxis: [], series: [] },
                { key: 'three_day_user_logged', title: "Users Logged after 3 Days", xaxis: [], series: [] },
                { key: 'three_day_user_logged_percent', title: "Users Logged after 3 Days %", xaxis: [], series: [] },
                // { key: 'usage_total', title: "Total Usage", xaxis: [], series: [] },
                // { key: 'usage_average', title: "Average Usage", xaxis: [], series: [] },
                // { key: 'usage_max', title: "Max Usage", xaxis: [], series: [] },
                // { key: 'usage_total_basic', title: "Total Basic Usage", xaxis: [], series: [] },
                // { key: 'usage_average_basic', title: "Average Basic Usage", xaxis: [], series: [] },
                // { key: 'usage_max_basic', title: "Max Basic Usage", xaxis: [], series: [] },
                // { key: 'usage_total_premium', title: "Total Premium Usage", xaxis: [], series: [] },
                // { key: 'usage_average_premium', title: "Average Premium Usage", xaxis: [], series: [] },
                // { key: 'usage_max_premium', title: "Max Premium Usage", xaxis: [], series: [] },
                { key: 'total_bots', title: "Total Bots", xaxis: [], series: [] },
                { key: 'market_commands', title: "Market Commands", xaxis: [], series: [] },
                { key: 'market_imports', title: "Market Imports", xaxis: [], series: [] },

            ],
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
                }
            },
            series: [
                {
                    name: "series-1",
                    data: [30, 40, 45, 50, 49, 60, 70, 91]
                }
            ]
        };
    }
    componentDidMount() {
        this.getStats();
    }

    renderCharts = () => {
        var chartData = [...this.state.chartData];
        var charts = [];
        if (this.state.loaded == false) return;
        this.state.statsArray.forEach(statObject => {
            Object.keys(statObject.stats).forEach(key => {
                var chart = chartData.find(chart => chart.key == key);
                if (chart) {
                    chart.xaxis.push(statObject.date);
                    chart.series.push(statObject.stats[key]);
                }
            });
        });

        chartData.forEach(chart => {
            charts.push(
                <div className='col-lg-6'>
                    <div className='card'>
                        <div className='card-header'>
                            <h4 class="card-title mb-0">{chart.title}</h4>
                        </div>
                        <div className='card-body apex-charts'>
                            <Chart
                                options={{
                                    chart: {
                                        id: chart.key
                                    },
                                    xaxis: {
                                        labels: {
                                            show: false
                                        },
                                        axisTicks: {
                                            show: false
                                        },
                                        categories: chart.xaxis
                                    }
                                }}
                                series={[{ name: chart.title, data: chart.series }]}
                                type="line"
                            // width="500"
                            />
                        </div>
                    </div>
                </div>
            );
        });


        return (
            <div className='row'>
                {charts}
            </div>
        );

        console.log(chartData);

    };

    getStats = async () => {
        var response = await server.get("/admin/stats");
        if (response && response.data.success) {
            this.setState({
                loaded: true,
                statsArray: response.data.stats
            });
        }
    };
    render() {
        return (
            <>
                {this.renderCharts()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomeCharts);