import {combineReducers} from 'redux'

import authReducer from "./authReducer"
import dataReducer from "./dataReducer"
import userReducer from "./userReducer"

export default combineReducers({
    // page:pageReducer,
    auth:authReducer,
    data:dataReducer,
    user:userReducer
    // notifications:notificationReducer,
    // quick_start:quickStartReducer,
    // builder:builderReducer
//     id:idReducer,
//     data:dataReducer,
//     auth:authReducer
})
