import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class SlashErrors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            name: "",
            coupon: '',
            partner_code: "",
            contact: "",
            disabled: false
        };
    }
    componentDidMount() {
        if (this.props.user.admin_perms.slasherrors != true) {
            history.push('/');
        }
        this.getSlashErrors();

    }

    getSlashErrors = async () => {
        var response = await server.get("/admin/slasherrors");

        if (response.data) {
            var errors = response.data;
            errors = errors.reverse();
            this.setState({ errors: errors });
        }
    };

    renderRequests = () => {
        var errors = [];

        this.state.errors.forEach((error, index) => {
            errors.push(
                <tr>
                    <th scope="row">{error.stack}</th>
                    <td>{error.command}</td>
                    <td>{moment(error.time).fromNow()}</td>
                    <td>{error.bot_id}</td>
                    <td>{error.email}</td>
                    <td><button onClick={async () => {
                        this.setState({ disabled: true });
                        var response = await server.post("/admin/slasherrors", {
                            bot_id: error.bot_id,
                            time: error.time,
                        });
                        var newErrors = [...this.state.errors];
                        newErrors.splice(index, 1);
                        this.setState({ errors: newErrors, disabled: false });
                    }} disabled={this.state.disabled} className="btn btn-success">Fixed</button></td>

                </tr>
            );
        });

        return (
            <tbody>
                {errors}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="SlashErrors">
                <div id="createPartnership" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Create Partnership</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div> */}
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/createpartnership", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var SlashErrors = [...this.state.SlashErrors];
                                    SlashErrors.push(response.data.partner);
                                    this.setState({ SlashErrors: SlashErrors, disabled: false });
                                    // this.setState({
                                    //     id: "",
                                    //     number: 1,
                                    //     reason: "",
                                    //     email: "",
                                    //     type: "",
                                    //     disabled: false
                                    // })
                                    window.$('#createPartnership').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Partner Name</label>
                                        <input placholder="TOM" value={this.state.name} onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Partner Code</label>
                                        <input placholder="TOM" value={this.state.partner_code} onChange={(e) => {
                                            this.setState({ partner_code: e.target.value.toLowerCase() });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Discount Code</label>
                                        <input placholder="TOM" value={this.state.coupon} onChange={(e) => {
                                            this.setState({ coupon: e.target.value.toUpperCase() });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Contact Information</label>
                                        <input placholder="TOM" value={this.state.contact} onChange={(e) => {
                                            this.setState({ contact: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} class="btn btn-primary " onClick={() => {
                                        this.getSlashErrors();
                                    }}>Refresh Errors</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* START ACTUAL CONTENT */}
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">SlashErrors</h5>
                            <button className='btn btn-success' onClick={() => {
                                this.getSlashErrors();
                            }}>Refresh</button>
                        </div>
                        <table class="table table-dark table-striped table-wrap">
                            <thead>
                                <tr>
                                    <th scope="col" >Stack</th>
                                    <th scope="col">Command</th>
                                    <th scope="col">Timestamp</th>
                                    <th scope="col">Bot ID</th>
                                    <th scope="col" >Email</th>
                                    <th scope="col" >Fixed</th>
                                </tr>
                            </thead>
                            {this.renderRequests()}
                        </table>
                    </div>

                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SlashErrors);