import server from '../api/server'
import history from "../history"
// import ReactGA from 'react-ga';


  export const setAuth = (params) =>{
    return async (dispatch,getState) =>{
        var invite = "";
        if(params != null && params != undefined){
            invite = `?invite=${params.invite}`
        };

        const response = await server.get(`/admin/auth`).catch(e =>{

        })
        
        if( response != undefined && response.data.success === true){
            dispatch(setUser())
            dispatch({
                type:"SET_AUTH",
                payload:true
            })



            // dispatch({
            //     type:"SET_LOADING",
            //     payload:false
            // })
        } else if(response.data.success == false && response.data.reason == 'auth'){
            window.location.replace("https://botghost.com/")
        } 
        else{
              window.location.replace(process.env.REACT_APP_API_SERVER + "/discord")
        }
    }
}

export const setUser = () =>{
    return async (dispatch,getState) =>{


        const response = await server.get(`/admin/user`).catch(e =>{

        })
        
        if( response != undefined && response.data != false){

            dispatch({
                type:"SET_USER",
                payload:response.data
            })
        }
    }
}

export const setSearchResults = (results) =>{
    return async (dispatch,getState) =>{
        results.show = true;
        dispatch({
            type:"SET_SEARCH_RESULTS",
            payload:results
        })
    }
}

export const getData = (params) =>{
    return async (dispatch,getState) =>{
        const response = await server.get("/hosting/data");

        console.log(response.data)
       if(response == undefined){
           window.location.replace("/")
       }else if(response.data.success === true){
           dispatch({
               type:"SET_DATA",
               payload:response.data.data
           })
        //    if(response.data.botData.bot != undefined){

        //     response.data.botData.premium = response.data.botData.bot.premium;
        //     const bot = newFeatures(response.data.botData.bot);
        //     response.data.botData.bot = bot;
        //     Sentry.setContext("user", {
        //         email: response.data.botData.user.email,
        //       });

        //       Sentry.setContext("bot", {
        //         bot: response.data.botData.bot,
        //       });
              
        //     dispatch({
        //         type:"SET_DATA",
        //         payload:response.data.botData
        //     })
        //     if(response.data.botData.user.quick_start != undefined){
        //         dispatch({
        //             type:"SET_QUICK_START",
        //             payload:response.data.botData.user.quick_start
        //         })
        //     }
        //     dispatch(getBots())
        //     // dispatch(fetchBotData())
        //     dispatch(getNotifications())
        //    }else{
        //        history.push("/dashboard/new")
        //    }
       }else{

       }
    }
}

