import update from 'immutability-helper';
const INIT_STATE = {
    user_id:null,
    "admin_perms":{
        "search":false,
        "coupons":false,
        "requests":false,
        "partnerships":false,
        "triggers":false,
        "slasherrors":false
      }
  }

  



  export default (state = INIT_STATE, action) => {
      switch(action.type){
        case 'SET_USER':
            return update(state,{
                user_id:{$set:action.payload.id},
                admin_perms:{$set:action.payload.admin_perms}
            })
        case "SET_SEARCH_RESULTS":
            return update(state,{
                search:{$set:action.payload}
            })
      default:
          return state
      }

  }