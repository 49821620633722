import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class Codes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: "",
            days: 0,
            loading: false,
            code: "",
            error: ""
        };
    }
    componentDidMount() {

        if (this.props.user.admin_perms.codes != true) {

            history.push('/');
        }

        // this.getCoupons();

    }

    createCode = async () => {
        this.setState({ loading: true, code: "" });
        var response = await server.post("/admin/premiumcode", {
            user_id: this.state.user_id,
            days: this.state.days
        });

        if (response && response.data && response.data.code) {
            this.setState({ code: response.data.code, loading: false });
        }
    };
    render() {
        return (
            <AdminContainer title="Coupons">
                <div className='col-lg-12'>
                    <div className='card'>
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Create Premium Code</h4>

                        </div>

                        <div className='card-body'>
                            <div>
                                <h5>Code</h5>
                                <p className='text-muted'>Create a new premium code. Specify a User ID to make the code user specific.</p>
                            </div>
                            <form>
                                <div className='gy-4 row'>

                                    <div class="col-xxl-4 col-md-6">
                                        <div>
                                            <label for="basiInput" class="form-label">User ID</label>
                                            <input value={this.state.user_id} onChange={(e) => {
                                                this.setState({ user_id: e.target.value });
                                            }} type="text" placeholder='136327647792726016' class="form-control" id="basiInput" />
                                        </div>
                                    </div>

                                    <div class="col-xxl-4 col-md-6">
                                        <div>
                                            <label for="basiInput" class="form-label">Premium Days</label>
                                            <input value={this.state.days} onChange={(e) => {
                                                this.setState({ days: e.target.value });
                                            }} type="number" placeholder='7' class="form-control" id="basiInput" />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2'>

                                    <p style={{ color: "red" }}>{this.state.code}</p>
                                </div>

                                <div class="d-flex align-items-start gap-3 mt-4">
                                    <button onClick={() => {
                                        this.setState({
                                            user_id: "",
                                            days: 0,
                                            loading: false
                                        });
                                    }} type="button" class="btn btn-light"> Reset</button>

                                    {this.state.loading == false ? <button onClick={() => {
                                        this.createCode();
                                    }} type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="pills-experience-tab"><i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Create Code</button>
                                        : <button disabled={true} type="button" class="btn btn-success btn-load right ms-auto nexttab nexttab">
                                            <span class="d-flex align-items-center">
                                                <span class="flex-grow-1 me-2">
                                                    Add
                                                </span>
                                                <span class="spinner-border flex-shrink-0" role="status">
                                                    <span class="visually-hidden">Add</span>
                                                </span>
                                            </span>
                                        </button>}

                                </div>
                            </form>

                        </div>
                    </div>

                </div >
            </AdminContainer >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Codes);