import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';
import { HomeStat } from './elements/HomeStat';

export class Requests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: []
        };
    }
    componentDidMount() {
        if (this.props.user.admin_perms.requests != true) {

            history.push('/');
        }
        this.getRequests();

    }

    getRequests = async () => {
        var response = await server.get("/admin/requests");

        if (response.data) {
            var requests = response.data;
            requests = requests.reverse();
            this.setState({ requests: requests });
        }
    };

    renderRequests = () => {
        var requests = [];

        this.state.requests.forEach((request, index) => {
            var status;
            if (request.status == undefined) {
                status = "default";
            } else {
                status = request.status;
            }

            var tableColor = "default";
            if (status == "contacted") {
                tableColor = "warning";
            } else if (status == "accepted") {
                tableColor = "success";
            }

            if (status != 'declined') {
                requests.push(
                    <tr className={`table-${tableColor}`}>
                        <th scope="row"><a className='text-decoration-underline text-primary' target="_blank" href={request.link}>{request.name}</a> <span className='text-muted'>{request.size}</span></th>
                        <td>
                            {request.contact_info}
                        </td>
                        <td>{moment(request.date).fromNow()}</td>
                        <td>{request.partnership_message}</td>
                        <td>
                            <select value={status} onChange={async (e) => {
                                var response = await server.post("/admin/partnershipstatus", { date: request.date, name: request.name, link: request.link, status: e.target.value });
                                if (response.data) {
                                    var requests = [...this.state.requests];
                                    requests[index] = response.data;
                                    this.setState({ requests: requests });
                                }
                            }} class="form-select mb-3" aria-label=".form-select-lg example">
                                <option selected>Set Application Status</option>
                                <option value="declined">Declined</option>
                                <option value="contacted">Contacted</option>
                                <option value="accepted">Accepted</option>
                            </select>

                        </td>
                    </tr>
                );
            }

        });

        return (
            <tbody>
                {requests}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="Requests">
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">Partnership Requests</h5>
                        </div>
                        <table class="table table-dark table-striped table-wrap">
                            <thead>
                                <tr>
                                    <th scope="col" className='col-2'>Name</th>
                                    <th scope="col" className='col-2'>Contact Info</th>
                                    <th scope="col" className='col-1'>Sent</th>
                                    <th scope="col" className='col-5'>Message</th>
                                    <th scope="col" className='col-2'>Status</th>
                                </tr>
                            </thead>
                            {this.renderRequests()}
                        </table>
                    </div>

                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);