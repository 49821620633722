import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
var test = {
    "user_id": "123456789",
};


var inputExamples = [
    {
        name: "Short Text",
        object: {
            id: '123456',
            name: 'input1',
            type: 'short',
            defaultValue: 'DEFAULT VALUE',
            placeholder: 'placeholder',
            title: 'Enter some text',
            description: 'You should enter some text here',
            minLength: 1,
            maxLength: 150,
            label: 'KEY',
            inputType: 'text',
            show: {
                id: "input_1",
                value: 1
            }
        }
    },
    {
        name: "Long Text",
        object: {
            id: 'longid',
            name: 'input1',
            type: 'long',
            defaultValue: 'DEFAULT VALUE',
            placeholder: 'placeholder',
            title: 'Enter some text',
            description: 'You should enter some text here',
            minLength: 1,
            maxLength: 150,
            label: 'KEY'
        }
    },
    {
        name: "Toggle",
        object: {
            type: 'toggle',
            name: 'toggle',
            defaultValue: true,
            id: 'toggle',
            title: 'Wow its a toggle',
            description: 'Wow a description'
        }
    },
    {
        name: "Select",
        object: {
            type: 'select',
            name: 'select',
            id: 'select',
            defaultValue: '2',
            title: 'Wow its a select',
            description: 'Wow a description',
            options: [
                {
                    label: 'Label 1',
                    value: '1'
                },
                {
                    label: 'Label 2',
                    value: '2'
                }
            ]
        }
    },
    {
        name: "Channel",
        object: {
            type: 'channel_select',
            name: 'channel',
            id: 'CHANGE ME',
            title: 'Wow its a select',
            description: '<p>Test</p>',
            html: true
        }
    },
    {
        name: "Role",
        object: {
            type: 'role_select',
            name: 'channel',
            id: 'CHANGE ME',
            title: 'Wow its a select',
            description: '<p>Test</p>',
            html: true
        }
    },
    {
        name: "Input Switch",
        object: {
            "id": "inputswitch",
            "name": "switch",
            "type": "input_switch",
            "default_type": "embed",
            "placeholder": "placeholder",
            "title": "A switcher",
            "description": "SWITCH",
            "minLength": 1,
            "maxLength": 150,
            "defaultValue": {
                "text": "Hello"
            },
            "label": "Label"
        }
    },
    {
        name: "Multi Role",
        object: {
            type: 'multi_role_select',
            name: 'multi_role',
            id: 'CHANGE ME',
            title: 'Select multi roles',
            description: 'Select',
            defaultValue: []
        }
    },
    {
        name: "Multi Channel Select",
        object: {
            type: 'multi_channel_select',
            name: 'multi_channel',
            id: 'CHANGE ME',
            title: 'Select multi channels',
            description: 'Select',
            defaultValue: []
        }
    },
    {
        name: "Multi Text Add",
        object: {
            "id": "word_add_input",
            "name": "word_add_input",
            "type": "word_add_input",
            "defaultValue": [],
            "limit": 5,
            "title": "Add words to an array",
            "description": "Add words to an array",
            "label": "dicks"
        }
    },
    {
        name: "Color Input",
        object: {
            "id": "colorpicker",
            "name": "color",
            "type": "color",
            "defaultValue": "#fff",
            "title": "pick color",
            "description": "color pick now"
        }
    },
    {
        name: "Event Slot",
        object:
        {
            "id": "event_slot",
            "name": "event_slot1",
            "type": "slot",
            "slot_type": "event",
            "defaultValue": [],
            "title": "SLOT",
            "description": "SLOT DESCRIPTION",
            "active_title": "Active Slots",
            "active_pretitle": "Big Dog Module",
            "inputs": [{
                "id": "slot_input1",
                "name": "slot_input1",
                "type": "long",
                "defaultValue": "DEFAULT VALUE",
                "placeholder": "placeholder",
                "title": "Enter some text",
                "description": "<p>Some HTML here <a href='https://www.twitch.tv/videos/1782684094' class='text-red' target='_blank'>Link here</a></p>",
                "html": true,
                "minLength": 1,
                "maxLength": 150,
                "label": "KEY"
            }],
            "individual_slot_name": "Slot",
            "individual_slot_description": "Slot description ytes yes",
            "slot_limit": 5,
            "slot_event": {
                "name": "",
                "type": "messageCreate",
                "event": true,
                "enabled": true,
                "module_id": "b5a76ec669d1d358c081e595b6201566",
                "options": [],
                "custom_variables": [],
                "errors": [],
                "handleErrors": true,
                "compressed_actions": "NobwRALgngDgpmAXGGAbAhgSwHYH0JwAeEYANGAE5wDOMA9ttQsgBICiAMhwPIAEAyjwAqvNgDU2AOSFkwcALZ0AVpmpJgAXXIR0FAOZwSicBAqY9BirgDGAC3TZscVElMBXOAF9yAN3SpMABN0AkDXCg9yAOwAazgwxHc4cmw6QLgASQARJDB0awhMBlwAZlwAVgBGAHZq3HQAM3QAJjBPDSA==",
                "id": ""
            }
        }

    }
];

export class Codes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: "",
            days: 0,
            create_error: "",
            loading: false,
            updateRequired: false,
            openModule: 0,
            modules: [],
            openExample: null,
            placeholder: {},
            newModule: {
                name: "t",
                description: "t",
                img: "t",
                category: "bug",
                sections: [{
                    title: "t",
                    description: "t",
                    inputs: [{
                        id: '123456',
                        name: 'input1',
                        type: 'short',
                        defaultValue: 'DEFAULT VALUE',
                        placeholder: 'placeholder',
                        title: 'Enter some text',
                        description: 'You should enter some text here',
                        minLength: 1,
                        maxLength: 150,
                        label: 'KEY',
                        inputType: 'text'
                    }]
                }],
                commands: [],
                events: []
            }
        };
    }
    componentDidMount() {

        if (this.props.user.admin_perms.modules != true) {

            history.push('/');
        }
        this.getModules();
        // this.getCoupons();

    }

    getModules = async () => {
        this.setState({ loading: true });
        const response = await server.get('/admin/modules');
        console.log(response);
        this.setState({ modules: response.data, loading: false });
    };

    renderModules = () => {
        var modules = [];

        this.state.modules.forEach((module, index) => {

            modules.push(
                <div class="accordion-item">
                    <h2 class="accordion-header" id="accordionwithplusExample1">
                        <button onClick={() => {
                            if (this.state.openModule == index) {
                                this.setState({ openModule: null });
                            } else {
                                this.setState({ openModule: index });
                            }
                        }} class={`accordion-button ${this.state.openModule === index ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#accor_plusExamplecollapse1" aria-expanded="true" aria-controls="accor_plusExamplecollapse1">
                            <span>{module.name} <span className='text-muted'>{module.description}</span> <span class={`badge bg-${!module.default ? "danger" : "success"}`}>{module.default == false ? "Inactive" : "Active"}</span></span>
                        </button>
                    </h2>
                    <div id={`accor_${this.props.index}`} class={`accordion-collapse collapse ${this.state.openModule === index ? "collapse show" : "collapsed"}`} aria-labelledby="accordionwithplusExample1" data-bs-parent="#accordionWithplusicon">

                        <div class="accordion-body">
                            <div className='mb-2'>
                                <h5>Live Version</h5>
                                <div style={{ width: "100%" }}>
                                    <JSONInput
                                        placeholder={{
                                            ...module,
                                            beta_version: {}
                                        }} // data to display
                                        theme="light_mitsuketa_tribute"
                                        locale={locale}
                                        colors={{
                                            // string: "#DAA520" // overrides theme colors with whatever color value you want
                                        }}
                                        onChange={(e) => {

                                            if (e.jsObject != undefined) {

                                                // var modules = [...this.state.modules];
                                                // modules[index] = newModule;
                                                // placeholder = newModule;
                                                this.setState({ updateRequired: true, placeholder: { ...e.jsObject } });
                                            }
                                        }}
                                        theme={"dark_vscode_tribute"}
                                        height="750px"
                                        width="100%"
                                    />
                                </div>
                            </div>


                            {this.state.updateRequired ? <div>
                                <button className="btn btn-primary waves-effect waves-light mt-3" onClick={() => {
                                    console.log(this.state.placeholder);
                                    server.post('/admin/module/' + module.id, { module: this.state.placeholder }).then((response) => {
                                        console.log(response);
                                    });
                                    // Increase the version number
                                    var modules = [...this.state.modules];
                                    modules[index] = this.state.placeholder;
                                    modules[index].version = modules[index].version + 1;
                                    this.setState({ modules: modules, updateRequired: false });
                                }}>Push Update</button>
                            </div> : null}

                        </div>
                    </div>
                </div>
            );
        });


        return modules;
    };
    render() {
        return (
            <AdminContainer title="Coupons">
                <div className='col-lg-12'>
                    <div className='card'>
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Create new module</h4>

                        </div>

                        <div className='card-body'>
                            <div>
                                <h5>New Module</h5>
                                <p className='text-muted'>Create a new module from JSON. The module will be put into testing mode and not made available until you have completed testing.</p>
                            </div>

                            <div className='mb-3'>
                                <span className="text-red" style={{ color: "red" }}>{this.state.create_error}</span>
                            </div>


                            <div>
                                <JSONInput
                                    placeholder={this.state.newModule} // data to display
                                    theme="light_mitsuketa_tribute"
                                    locale={locale}
                                    colors={{
                                        // string: "#DAA520" // overrides theme colors with whatever color value you want
                                    }}
                                    onChange={async (e) => {
                                        if (e.jsObject != undefined) {
                                            this.setState({ newModule: e.jsObject });
                                        }
                                    }}
                                    theme={"dark_vscode_tribute"}
                                    height="350px"
                                    width="100%"
                                />
                            </div>

                            <div>
                                <button className="btn btn-primary waves-effect waves-light mt-3" onClick={async () => {
                                    var newModule = { ...this.state.newModule };
                                    this.setState({ create_error: "" });
                                    // Validate the module
                                    if (newModule.name == undefined || newModule.name == "") {
                                        this.setState({ create_error: "Module must have a name" });
                                        return;
                                    }
                                    if (newModule.description == undefined || newModule.description == "") {
                                        this.setState({ create_error: "Module must have a description" });
                                        return;
                                    }
                                    // img
                                    if (newModule.img == undefined || newModule.img == "") {
                                        this.setState({ create_error: "Module must have an image" });
                                        return;
                                    }

                                    // category
                                    if (newModule.category == undefined || newModule.category == "") {
                                        this.setState({ create_error: "Module must have a category" });
                                        return;
                                    }

                                    // sections
                                    if (newModule.sections == undefined || newModule.sections.length == 0) {
                                        this.setState({ create_error: "Module must have sections" });
                                        return;
                                    }

                                    // Go through each section and validate
                                    for (var i = 0; i < newModule.sections.length; i++) {
                                        var section = newModule.sections[i];
                                        if (section.title == undefined || section.title == "") {
                                            this.setState({ create_error: "Section must have a name" });
                                            return;
                                        }
                                        if (section.description == undefined || section.description == "") {
                                            this.setState({ create_error: "Section must have a description" });
                                            return;
                                        }
                                        if (section.inputs == undefined || section.inputs.length == 0) {
                                            this.setState({ create_error: "Section must have atleast one input" });
                                            return;
                                        }

                                    }

                                    newModule.version = 1;
                                    newModule.live = false;

                                    await server.post('/admin/newModule', { module: newModule });
                                    window.location.reload();

                                }}>Create Module</button>

                                <button style={{
                                    marginLeft: "15px"
                                }} className='btn btn-danger waves-effect waves-light mt-3' onClick={async () => {
                                    await server.get("/admin/updateCustomModules");
                                }}>Refresh Module Interactions</button>
                            </div>
                        </div>
                    </div>

                </div >

                <div className='col-lg-12'>
                    <div class="row">
                        <div class="col-12">
                            <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                <h5 class="mb-0 pb-1">Existing Default Modules</h5>
                            </div>
                            <div class="accordion custom-accordionwithicon-plus" id="accordionWithplusicon">
                                {this.renderModules()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-12'>
                    <div class="row">
                        <div class="col-12">
                            <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                <h5 class="mb-0 pb-1">Input Examples</h5>
                            </div>
                            <div class="accordion custom-accordionwithicon-plus" id="accordionWithplusicon">
                                {inputExamples.map((example, index) => {
                                    return (
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="accordionwithplusExample1">
                                                <button onClick={() => {
                                                    if (this.state.openExample == index) {
                                                        this.setState({ openExample: null });
                                                    } else {
                                                        this.setState({ openExample: index });
                                                    }
                                                }} class={`accordion-button ${this.state.openExample === index ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#accor_plusExamplecollapse1" aria-expanded="true" aria-controls="accor_plusExamplecollapse1">
                                                    <span>{example.name} <span className='text-muted'>{example.description}</span></span>
                                                </button>
                                            </h2>
                                            <div id={`accor_${index}_examples`} class={`accordion-collapse collapse ${this.state.openExample === index ? "collapse show" : "collapsed"}`} aria-labelledby="accordionwithplusExample1" data-bs-parent="#accordionWithplusicon">
                                                <div class="accordion-body">
                                                    <JSONInput
                                                        placeholder={example.object} // data to display
                                                        theme="light_mitsuketa_tribute"
                                                        locale={locale}
                                                        colors={{
                                                            // string: "#DAA520" // overrides theme colors with whatever color value you want
                                                        }}
                                                        viewOnly={true}
                                                        theme={"dark_vscode_tribute"}
                                                        height="350px"
                                                        width="100%"
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminContainer >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Codes);