import React, { Component } from 'react'
import { connect } from 'react-redux'

export class HomeStat extends Component {
  render() {
    return (
        <div class="col-xl-3 col-md-6">

        <div class="card card-animate">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                        <p
                            class="text-uppercase fw-medium text-muted text-truncate mb-0">
                            {this.props.title}</p>
                    </div>
                    <div class="flex-shrink-0">
                        <h5 class={`text-${this.props.icontype} fs-14 mb-0`}>
                            <i class="ri-arrow-right-down-line fs-13 align-middle"></i>
                            {this.props.percent}%
                        </h5>
                    </div>
                </div>
                <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                        <h4 class="fs-22 fw-semibold ff-secondary mb-4"><span
                            class="counter-value" data-target="15232">{this.props.money == true ? "$":""}{this.props.amount}</span></h4>
                        {/* <a href="" class="text-decoration-underline">View all orders</a> */}
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                        <span class="avatar-title bg-soft-info rounded fs-3">
                            <i class={`${this.props.icon} text-${this.props.icontype}`}></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomeStat)