import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class Triggers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "exact_includes",
            response: "",
            priority: "",
            triggers_string: "",
            disabled: false,
            triggers: [],
            id: "",
        };
    }
    componentDidMount() {
        if (this.props.user.admin_perms.triggers != true) {

            history.push('/');
        }
        this.getTriggers();

    }

    getTriggers = async () => {
        var response = await server.get("/admin/supporttriggers");

        if (response.data) {
            var triggers = response.data.triggers;
            this.setState({ triggers });
        }
    };

    renderTriggers = () => {
        var triggers = [];

        this.state.triggers.forEach((trigger, index) => {
            triggers.push(
                <tr>
                    <th scope="row">{trigger.id}</th>
                    <td>{trigger.response}</td>
                    {/* <td>{trigger.type}</td> */}
                    <td>{trigger.priority == "high" ? "High" : "Low"}</td>
                    <td>{trigger.triggers.join(", ")}</td>
                    <td><button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#createTrigger" onClick={() => {
                        this.setState({
                            type: "exact_includes",
                            response: trigger.response,
                            triggers_string: trigger.triggers.join(", "),
                            disabled: false,
                            id: trigger.id,
                            priority: trigger.priority ? trigger.priority : "low",
                            edit: true
                        });

                    }}>Edit</button> <button className="btn btn-danger mt-1" onClick={async () => {
                        var response = await server.delete(`/admin/supporttriggers/${trigger.id}`);
                        if (response.data) {
                            this.getTriggers();
                        }
                    }}>Delete </button></td>
                </tr>
            );
        });

        return (
            <tbody>
                {triggers}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="Triggers">
                <div id="createTrigger" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Create Trigger</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/supporttriggers", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var triggers = [...this.state.triggers];
                                    if (this.state.edit == true) {
                                        triggers.forEach((trigger, index) => {
                                            if (trigger.id == this.state.id) {
                                                triggers[index] = response.data.trigger;
                                            }
                                        });
                                    } else {
                                        triggers.push(response.data.trigger);
                                    }

                                    this.setState({
                                        triggers: triggers,
                                        type: "exact_includes",
                                        response: "",
                                        triggers_string: "",
                                        disabled: false,
                                        edit: true
                                    });

                                    window.$('#createTrigger').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Trigger Response</label>
                                        <textarea style={{ height: "150px" }} placholder="TOM" value={this.state.response} onChange={(e) => {
                                            this.setState({ response: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Triggers (Seperate with commas)</label>
                                        <textarea style={{ height: "100px" }} placholder="TOM" value={this.state.triggers_string} onChange={(e) => {
                                            this.setState({ triggers_string: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Trigger Priority</label>
                                        <select required value={this.state.priority} onChange={(e) => {
                                            this.setState({ priority: e.target.value });
                                        }} class="form-control" data-choices name="choices-single-default">
                                            <option value=""></option>
                                            <option value="high">High</option>
                                            <option value="low">Low</option>
                                        </select>
                                    </div>

                                    {/* 
                                    
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Partner Code</label>
                                        <input placholder="TOM" value={this.state.partner_code} onChange={(e) => {
                                            this.setState({ partner_code: e.target.value.toLowerCase() })
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Discount Code</label>
                                        <input placholder="TOM" value={this.state.coupon} onChange={(e) => {
                                            this.setState({ coupon: e.target.value.toUpperCase() })
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Contact Information</label>
                                        <input placholder="TOM" value={this.state.contact} onChange={(e) => {
                                            this.setState({ contact: e.target.value })
                                        }} required type="text" class="form-control" id="name" />
                                    </div> */}

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} class="btn btn-primary " onClick={() => {
                                        this.getTriggers();
                                    }}>Add Trigger</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* START ACTUAL CONTENT */}
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">Triggers</h5>
                            <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#createTrigger" onClick={() => {
                                this.setState({
                                    type: "exact_includes",
                                    response: "",
                                    triggers_string: "",
                                    disabled: false,
                                    edit: false
                                });
                            }}>Add Trigger</button>

                        </div>
                    </div>
                    <table class="table table-dark table-striped table-wrap">
                        <thead>
                            <tr>
                                <th scope="col" >ID</th>
                                <th scope="col">Response</th>
                                <th scope="col">Type</th>
                                <th scope="col">Priority</th>
                                <th scope="col">Triggers</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {this.renderTriggers()}
                    </table>
                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Triggers);