import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import server from '../../api/server';
export class BotItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonDisable: false
        };
    }
    componentDidMount() {
        console.log(this.props.data.bot.subscription.sub_type);
    }
    render() {
        return (
            <div class="accordion-item">
                <h2 class="accordion-header" id="accordionwithplusExample1">
                    <button onClick={() => {
                        this.props.onClick();
                    }} class={`accordion-button ${this.props.open == true ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#accor_plusExamplecollapse1" aria-expanded="true" aria-controls="accor_plusExamplecollapse1">
                        <span>{this.props.data.name} <span className='text-muted'>{this.props.data.bot_id}</span> {this.props.last_bot == true ? <span class="badge bg-success">Last Bot</span> : ""} {this.props.data.bot.premium == true ? <span class="badge bg-primary">Premium</span> : ""}</span>
                    </button>
                </h2>
                <div id={`accor_${this.props.index}`} class={`accordion-collapse collapse ${this.props.open ? "collapse show" : "collapsed"}`} aria-labelledby="accordionwithplusExample1" data-bs-parent="#accordionWithplusicon">

                    <div class="accordion-body">
                        <h5>Bot Object</h5>
                        <ReactJson displayDataTypes={false} theme={"ocean"} collapsed={1} src={this.props.data} />

                        <div className='mt-2'>
                            <h5>Actions</h5>
                            {this.props.data && this.props.data.bot.premium == true && this.props.data.bot.subscription.sub_type != "freepremium" ? <button onClick={async () => {
                                this.setState({ buttonDisable: true });
                                await server.post("/admin/revokeToken", {
                                    bot_id: this.props.data.bot_id,
                                    user_id: this.props.data.user_id
                                });
                                this.setState({ buttonDisable: false });
                            }} disabled={this.state.buttonDisable} className='btn btn-secondary waves-effect waves-light' type="button">Revoke Premium</button> : ""}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BotItem);