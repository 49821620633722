import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class AITriggers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "exact_includes",
            response: "",
            priority: "",
            question: "",
            answer: "",
            triggers_string: "",
            disabled: false,
            responses: [],
            id: "",
        };
    }
    componentDidMount() {
        if (this.props.user.admin_perms.ai != true) {
            history.push('/');
        }
        this.getTriggers();

    }

    getTriggers = async () => {
        var response = await server.get("/admin/airesponses");

        if (response.data) {
            var responses = response.data.responses;
            this.setState({ responses });
        }
    };

    renderTriggers = () => {
        var triggers = [];

        this.state.responses.forEach((trigger, index) => {
            triggers.push(
                <tr>
                    <th scope="row">{trigger.id}</th>
                    <td>{trigger.question}</td>
                    <td>{trigger.answer}</td>
                    {/* <td>{trigger.type}</td> */}
                    <td><button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#createTrigger" onClick={() => {
                        this.setState({
                            type: "exact_includes",
                            question: trigger.question,
                            answer: trigger.answer,
                            disabled: false,
                            id: trigger.id,

                            edit: true
                        });

                    }}>Edit</button> <button className="btn btn-danger mt-1" onClick={async () => {
                        var response = await server.delete(`/admin/airesponses/${trigger.id}`);
                        if (response.data) {
                            this.getTriggers();
                        }
                    }}>Delete </button></td>
                </tr>
            );
        });

        return (
            <tbody>
                {triggers}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="Responses">
                <div id="createTrigger" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Create Response</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/airesponses", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var responses = [...this.state.responses];
                                    if (this.state.edit == true) {
                                        responses.forEach((res, index) => {
                                            if (res.id == this.state.id) {
                                                responses[index] = response.data.trigger;
                                            }
                                        });
                                    } else {
                                        responses.push(response.data.trigger);
                                    }

                                    this.setState({
                                        responses: responses,
                                        type: "exact_includes",
                                        question: "",
                                        answer: "",
                                        response: "",
                                        triggers_string: "",
                                        disabled: false,
                                        edit: true
                                    });

                                    window.$('#createTrigger').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Question</label>
                                        <textarea style={{ height: "150px" }} placholder="TOM" value={this.state.question} onChange={(e) => {
                                            this.setState({ question: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Answer</label>
                                        <textarea style={{ height: "100px" }} placholder="TOM" value={this.state.answer} onChange={(e) => {
                                            this.setState({ answer: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>


                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} class="btn btn-primary " onClick={() => {
                                        // this.getTriggers();
                                    }}>{this.state.edit ? "Edit" : "Add"} Response</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* START ACTUAL CONTENT */}
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">AI Responses</h5>
                            <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#createTrigger" onClick={() => {
                                this.setState({
                                    type: "exact_includes",
                                    response: "",
                                    triggers_string: "",
                                    disabled: false,
                                    edit: false
                                });
                            }}>Add Response</button>

                        </div>
                    </div>
                    <table class="table table-dark table-striped table-wrap">
                        <thead>
                            <tr>
                                <th scope="col" >ID</th>
                                <th scope="col">Question</th>
                                <th scope="col">Answer</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        {this.renderTriggers()}
                    </table>
                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AITriggers);