import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class Coupons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            coupons: [],
            name: "",
            percentage: 10
        };
    }
    componentDidMount() {

        if (this.props.user.admin_perms.coupons != true) {

            history.push('/');
        }

        this.getCoupons();

    }

    getCoupons = async () => {
        var response = await server.get("/admin/coupons");

        if (response.data) {
            this.setState({ coupons: response.data });
        }
    };

    renderRequests = () => {
        var coupons = [];

        this.state.coupons.forEach(coupon => {
            coupons.push(
                <tr>
                    <th scope="row">{coupon.coupon.name}</th>
                    <td>{coupon.coupon.redemptions}</td>
                    <td>{coupon.coupon.discount_type}</td>
                    <td>{coupon.coupon.discount_amount || `${coupon.coupon.discount_percentage}%`}</td>
                </tr>
            );
        });

        return (
            <tbody>
                {coupons}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="Coupons">
                <div id="addCouponModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Add Coupon Code</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/addcoupon", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var coupons = [...this.state.coupons];
                                    coupons.push(response.data.coupon);
                                    this.setState({ coupons: coupons });
                                    // this.setState({
                                    //     id: "",
                                    //     number: 1,
                                    //     reason: "",
                                    //     email: "",
                                    //     type: "",
                                    //     disabled: false
                                    // })
                                    window.$('#addCouponModal').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Coupon Name</label>
                                        <input placholder="TOM" value={this.state.name} onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }} required type="text" class="form-control" id="name" />

                                    </div>

                                    <div className='mb-3'>
                                        <label for="sub_email" class="form-label">Discount Percentage</label>
                                        <input placeholder="50" value={this.state.percentage} onChange={(e) => {
                                            this.setState({ percentage: e.target.value });
                                        }} required type="number" class="form-control" id="sub_email" />
                                        <div id="passwordHelpBlock" class="form-text">
                                            Will be applied to all subscriptions.
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} type="submit" class="btn btn-primary ">Add Tokens</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* START ACTUAL CONTENT */}
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">Coupons</h5>
                            <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#addCouponModal">Add Coupon Code</button>
                        </div>
                        <table class="table table-dark table-striped table-wrap">
                            <thead>
                                <tr>
                                    <th scope="col" >Name</th>
                                    <th scope="col">Redemptions</th>
                                    <th scope="col">Type</th>
                                    <th scope="col" >Amount</th>
                                </tr>
                            </thead>
                            {this.renderRequests()}
                        </table>
                    </div>

                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);