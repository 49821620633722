import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../../api/server'

export class MoveSubscriptionModal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         id:"",
         user_id:"783206682356482098",
         disabled:false
      }
    }
    render() {
        return (
            <div id="moveSubscriptionModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">Move Subscription</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <form onSubmit={async (e) =>{
                                e.preventDefault();
                                // source_user_id

                                // this.setState({disabled:true})
                                // var data = this.state;
                                // data.user_id = this.props.user_id;
                                var response = await server.post("/admin/move_subscription",{
                                    user_id:this.state.user_id,
                                    source_user_id:this.props.user_id,
                                    subscription:this.props.subscription,
                                });
                               
                                // if(response && response.data && response.data.success == true){
                                //     this.setState({
                                //         id:"",
                                //         number:1,
                                //         reason:"",
                                //         email:"",
                                //         type:"",
                                //         disabled:false
                                //     })
                                //     window.$('#addPremiumModal').modal('toggle');
                                //     this.props.success(response.data.user);
                                    
                                // }
                            }}>
                        <div class="modal-body">


                                <div className='mb-3'>
                                    <label for="reason" class="form-label">User ID</label>
                                    <input placeholder="User ID" value={this.state.user_id} onChange={(e) =>{
                                        this.setState({user_id:e.target.value})
                                    }} required type="text" class="form-control" id="reason" />
                                    <div id="passwordHelpBlock" class="form-text">
                                        User ID of the user to move the subscription to.
                                    </div>
                                </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                            <button disabled={this.state.disabled} type="submit" class="btn btn-primary ">Move Subscription</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MoveSubscriptionModal)