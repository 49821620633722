import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';


export class Partnerships extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partnerships: [],
            name: "",
            coupon: '',
            partner_code: "",
            contact: ""
        };
    }
    componentDidMount() {
        this.getPartnerships();

        if (this.props.user.admin_perms.partnerships != true) {
            history.push('/');
        }
    }

    getPartnerships = async () => {
        var response = await server.get("/admin/partnerships");

        if (response.data) {
            this.setState({ partnerships: response.data });
        }
    };

    renderRequests = () => {
        var partnerships = [];

        this.state.partnerships.forEach(partnership => {
            partnerships.push(
                <tr>
                    <th scope="row">{partnership.name}</th>
                    <td>{partnership.coupon}</td>
                    <td>{`https://botghost.com/?partner=${partnership.partner_code}`}</td>
                    <td>{partnership.contact}</td>
                    <td>{partnership.sign_ups}</td>
                    <td>{partnership.premium_sign_ups}</td>
                </tr>
            );
        });

        return (
            <tbody>
                {partnerships}
            </tbody>
        );
    };
    render() {
        return (
            <AdminContainer title="Partnerships">
                <div id="createPartnership" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="myModalLabel">Create Partnership</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <form onSubmit={async (e) => {
                                e.preventDefault();

                                this.setState({ disabled: true });
                                var response = await server.post("/admin/createpartnership", this.state);

                                if (response && response.data && response.data.success == true) {
                                    var partnerships = [...this.state.partnerships];
                                    partnerships.push(response.data.partner);
                                    this.setState({ partnerships: partnerships, disabled: false });
                                    // this.setState({
                                    //     id: "",
                                    //     number: 1,
                                    //     reason: "",
                                    //     email: "",
                                    //     type: "",
                                    //     disabled: false
                                    // })
                                    window.$('#createPartnership').modal('toggle');
                                    // this.props.success(response.data.user);

                                }
                            }}>
                                <div class="modal-body">
                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Partner Name</label>
                                        <input placholder="TOM" value={this.state.name} onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Partner Code</label>
                                        <input placholder="TOM" value={this.state.partner_code} onChange={(e) => {
                                            this.setState({ partner_code: e.target.value.toLowerCase() });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Discount Code</label>
                                        <input placholder="TOM" value={this.state.coupon} onChange={(e) => {
                                            this.setState({ coupon: e.target.value.toUpperCase() });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                    <div className='mb-3'>
                                        <label for="subscription_id" class="form-label">Contact Information</label>
                                        <input placholder="TOM" value={this.state.contact} onChange={(e) => {
                                            this.setState({ contact: e.target.value });
                                        }} required type="text" class="form-control" id="name" />
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    <button disabled={this.state.disabled} type="submit" class="btn btn-primary ">Create Partnership</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/* START ACTUAL CONTENT */}
                <div class="row">
                    <div className='col-lg-12 col-md-12'>
                        <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 class="mb-0 pb-1">Partnerships</h5>
                            <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#createPartnership">Create Partnership</button>
                        </div>
                        <table class="table table-dark table-striped table-wrap">
                            <thead>
                                <tr>
                                    <th scope="col" >Name</th>
                                    <th scope="col">Discount Code</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">Contact</th>
                                    <th scope="col" >Sign Ups</th>
                                    <th scope="col" >Premium Sign Ups</th>
                                </tr>
                            </thead>
                            {this.renderRequests()}
                        </table>
                    </div>

                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Partnerships);