import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../../api/server'

export class AddPremiumModal extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         id:"",
         number:1,
         reason:"",
         email:"",
         type:"",
         disabled:false
      }
    }
    render() {
        return (
            <div id="addPremiumModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style={{ display: "none" }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myModalLabel">Add Premium Tokens</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <form onSubmit={async (e) =>{
                                e.preventDefault();
                                
                                this.setState({disabled:true})
                                var data = this.state;
                                data.user_id = this.props.user_id;
                                var response = await server.post("/admin/addtokens",data);
                               
                                if(response && response.data && response.data.success == true){
                                    this.setState({
                                        id:"",
                                        number:1,
                                        reason:"",
                                        email:"",
                                        type:"",
                                        disabled:false
                                    })
                                    window.$('#addPremiumModal').modal('toggle');
                                    this.props.success(response.data.user);
                                    
                                }
                            }}>
                        <div class="modal-body">
                                <div className='mb-3'>
                                    <label for="subscription_id" class="form-label">Subscription ID</label>
                                    <input placholder="MOD" value={this.state.id} onChange={(e) =>{
                                        this.setState({id:e.target.value})
                                    }} required type="text" class="form-control" id="subscription_id" />
                                    <div id="passwordHelpBlock" class="form-text">
                                        Subscription ID or another identifier.
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <label for="sub_email" class="form-label">Email</label>
                                    <input placeholder="mod@botghost.com" value={this.state.email} onChange={(e) =>{
                                        this.setState({email:e.target.value})
                                    }} required type="text" class="form-control" id="sub_email" />
                                    <div id="passwordHelpBlock" class="form-text">
                                        Subscription Email or other (mod@mod.com)
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <label for="sub_email" class="form-label">Subscription Type</label>
                                    <select required value={this.state.type} onChange={(e) =>{
                                        this.setState({type:e.target.value})
                                    }} class="form-select  mb-3" aria-label="example">
                                        <option selected></option>
                                        <option value="lifetime">Lifetime</option>
                                        <option value="yearly">Yearly</option>
                                        <option value="monthly">Monthly</option>
                                    </select>

                                </div>

                                <div className='mb-3'>
                                    <label for="tokens" class="form-label">Number of Tokens</label>
                                    <input placeholder="5" value={this.state.number} onChange={(e) =>{
                                        this.setState({number:e.target.value})
                                    }} required type="number" class="form-control" id="tokens" />
                                </div>

                                <div className='mb-3'>
                                    <label for="reason" class="form-label">Reason</label>
                                    <input placeholder="Giving tokens to a mod" value={this.state.reason} onChange={(e) =>{
                                        this.setState({reason:e.target.value})
                                    }} required type="text" class="form-control" id="reason" />
                                    <div id="passwordHelpBlock" class="form-text">
                                        Short reason why you are giving them tokens.
                                    </div>
                                </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                            <button disabled={this.state.disabled} type="submit" class="btn btn-primary ">Add Tokens</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddPremiumModal)