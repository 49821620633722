import React, { Component } from 'react'
import { Sidebar } from './elements/Sidebar'
import Topbar from './elements/Topbar'
import { setAuth,setUser } from '../actions';
import { connect } from 'react-redux'
export class AdminContainer extends Component {

  componentDidMount() {
    var params = null;
    if(this.props.auth == false){
        this.props.setAuth(params)
    }

    if(this.props.user.user_id == null){
      // this.props.setUser()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.auth == true && this.props.loading == true){
      this.props.getData()

    }
    
  }
  render() {
    if(this.props.loading == true){
      return null;
    }else{
      return (
        <div id="layout-wrapper">
          <Topbar />
          <Sidebar />
          <div class="main-content">
  
            <div class="page-content">
              <div class="container-fluid">
  
                <div class="row">
                  <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 class="mb-sm-0">{this.props.title || 'Admin'}</h4>
  
  
  
  
                    </div>
                  </div>
                </div>
  
                <div className='row'>
                        <div className='col'>
                          <div className='h-100'>
                            {this.props.children}
                          </div>
                        </div>
                      </div>
              </div>
  
            </div>
  
  
          </div>
  
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  auth:state.auth,
  loading:state.data.loading,
  user:state.user
})


const mapDispatchToProps = {
  setAuth,
  setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)