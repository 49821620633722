import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";
var test = {
    "user_id": "123456789",
};




export class SeoModulePages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: "",
            days: 0,
            create_error: "",
            loading: false,
            updateRequired: false,
            openModule: 0,
            modules: [],
            openExample: null,
            placeholder: {},
            newModule: {
                live: false,
                title: "",
                description: "",
                slug: "roblox",
                category: "Roblox",
                "features_description": "Features description",
                "sections_description": "sections description",
                "faqs_description": "faqs description",
                "icon": "https://cdn.discordapp.com/attachments/822429891357573152/1147104133820723221/BOTGHOST_LOGO_HD_12.png",
                features: [
                    {
                        title: 'TITLE',
                        description:
                            "Description",
                        image: 'https://cdn.discordapp.com/attachments/902887231780438057/1150863395701137628/image.png',
                    },

                ],
                faqs: [
                    {
                        question: "How do I get started",
                        answer: "YES"
                    }
                ],
                sections: [
                    {
                        title: "Schedule messages with Timers",
                        description: "Send timed messages - both text or embed - every specific time on every specific day. Use the timed messages to remind your members about your rules or an upcoming event.",
                        image: "http://localhost:3000/img/home_images/multibot.PNG"
                    },
                ]
            }
        };
    }
    componentDidMount() {

        if (this.props.user.admin_perms.seo_pages != true) {

            history.push('/');
        }
        this.getModules();
        // this.getCoupons();

    }

    getModules = async () => {
        this.setState({ loading: true });
        const response = await server.get('/admin/seo_pages');
        console.log(response);
        this.setState({ modules: response.data, loading: false });
    };

    renderModules = () => {
        var modules = [];

        this.state.modules.forEach((module, index) => {

            modules.push(
                <div class="accordion-item">
                    <h2 class="accordion-header" id="accordionwithplusExample1">
                        <button onClick={() => {
                            if (this.state.openModule == index) {
                                this.setState({ openModule: null });
                            } else {
                                this.setState({ openModule: index });
                            }
                        }} class={`accordion-button ${this.state.openModule === index ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#accor_plusExamplecollapse1" aria-expanded="true" aria-controls="accor_plusExamplecollapse1">
                            <span>{module.category} <span class={`badge bg-${!module.live ? "danger" : "success"}`}>{module.live == false ? "Inactive" : "Active"}</span></span>
                        </button>
                    </h2>
                    <div id={`accor_${this.props.index}`} class={`accordion-collapse collapse ${this.state.openModule === index ? "collapse show" : "collapsed"}`} aria-labelledby="accordionwithplusExample1" data-bs-parent="#accordionWithplusicon">

                        <div class="accordion-body">
                            <div className='mb-2'>
                                <h5>Live Version</h5>
                                <div style={{ width: "100%" }}>
                                    <JSONInput
                                        placeholder={{
                                            ...module,
                                            beta_version: {}
                                        }} // data to display
                                        theme="light_mitsuketa_tribute"
                                        locale={locale}
                                        colors={{
                                            // string: "#DAA520" // overrides theme colors with whatever color value you want
                                        }}
                                        onChange={(e) => {

                                            if (e.jsObject != undefined) {

                                                // var modules = [...this.state.modules];
                                                // modules[index] = newModule;
                                                // placeholder = newModule;
                                                this.setState({ updateRequired: true, placeholder: { ...e.jsObject } });
                                            }
                                        }}
                                        theme={"dark_vscode_tribute"}
                                        height="750px"
                                        width="100%"
                                    />
                                </div>
                            </div>


                            {this.state.updateRequired ? <div>
                                <button className="btn btn-primary waves-effect waves-light mt-3" onClick={async () => {
                                    console.log(this.state.placeholder);
                                    var response = await server.post('/admin/update/seo_module_page/' + module.slug, { module: this.state.placeholder });

                                    this.setState({
                                        updateRequired: false,
                                        openModule: null,
                                        modules: response.data.modules
                                    });




                                    // // Increase the version number
                                    // var modules = [...this.state.modules];
                                    // modules[index] = this.state.placeholder;
                                    // modules[index].version = modules[index].version + 1;
                                    // this.setState({ modules: modules, updateRequired: false });
                                }}>Push Update</button>
                            </div> : null}

                        </div>
                    </div>
                </div>
            );
        });


        return modules;
    };
    render() {
        return (
            <AdminContainer title="SEO Module Pages">
                <div className='col-lg-12'>
                    <div className='card'>
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Create new SEO Module Page</h4>

                        </div>

                        <div className='card-body'>
                            <div>
                                <h5>New SEO Page</h5>
                                <p className='text-muted'>Create a new SEO module page from JSON. </p>
                            </div>

                            <div className='mb-3'>
                                <span className="text-red" style={{ color: "red" }}>{this.state.create_error}</span>
                            </div>


                            <div>
                                <JSONInput
                                    placeholder={this.state.newModule} // data to display
                                    theme="light_mitsuketa_tribute"
                                    locale={locale}
                                    colors={{
                                        // string: "#DAA520" // overrides theme colors with whatever color value you want
                                    }}
                                    onChange={async (e) => {
                                        if (e.jsObject != undefined) {
                                            this.setState({ newModule: e.jsObject });
                                        }
                                    }}
                                    theme={"dark_vscode_tribute"}
                                    height="350px"
                                    width="100%"
                                />
                            </div>

                            <div>
                                <button className="btn btn-primary waves-effect waves-light mt-3" onClick={async () => {
                                    var newModule = { ...this.state.newModule };
                                    this.setState({ create_error: "" });
                                    // Validate the module


                                    newModule.version = 1;
                                    newModule.live = false;

                                    var response = await server.post('/admin/new_seo_module_page', { module: newModule });
                                    if (response.data.success == false) {
                                        return this.setState({ create_error: response.data.reason });

                                    }
                                    window.location.reload();

                                }}>Create SEO Module Page</button>
                            </div>
                        </div>
                    </div>

                </div >

                <div className='col-lg-12'>
                    <div class="row">
                        <div class="col-12">
                            <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                                <h5 class="mb-0 pb-1">Existing SEO Pages</h5>
                            </div>
                            <div class="accordion custom-accordionwithicon-plus" id="accordionWithplusicon">
                                {this.renderModules()}
                            </div>
                        </div>
                    </div>
                </div>

            </AdminContainer >
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SeoModulePages);