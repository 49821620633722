import update from 'immutability-helper';
const INIT_STATE = {
    loading:true,
    user:{},
    nodes:[],
    search:{
        user:{},
        bots:[]
    }
  }

  



  export default (state = INIT_STATE, action) => {
      switch(action.type){
        // case 'SET_DATA':
        //     return update(state, {
        //         user: { $set: action.payload.user },
        //         nodes: { $set: action.payload.nodes },
        //         loading:{$set:false}

        //     })
        case 'SET_AUTH':
            return update(state,{
                loading:{$set:false}
            })
        case "SET_SEARCH_RESULTS":
            return update(state,{
                search:{$set:action.payload}
            })
      default:
          return state
      }

  }