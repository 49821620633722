import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
export class Sidebar extends Component {
    render() {
        return (
            <div class="app-menu navbar-menu">
                <div class="navbar-brand-box">

                    <a href="index.html" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22" />
                        </span>
                        <span class="logo-lg">
                            <img height="40" src={process.env.PUBLIC_URL + "/assets/icons/logo-red.png"} alt="" />
                        </span>
                    </a>
                    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                        id="vertical-hover">
                        <i class="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div class="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul class="navbar-nav" id="navbar-nav">
                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Menu</span></li>
                            <Link to="/" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-home-5-line"></i> <span data-key="t-widgets">Dashboard</span>
                                </a>
                            </Link>

                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Search</span></li>

                            <Link to="/search" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-robot-line"></i> <span data-key="t-widgets">Search</span>
                                </a>
                            </Link>

                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Modules</span></li>
                            <Link to="/modules" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-coupon-line"></i> <span data-key="t-widgets">Modules</span>
                                </a>
                            </Link>

                            <Link to="/seo-module-pages" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-coupon-line"></i> <span data-key="t-widgets">SEO Module Pages</span>
                                </a>
                            </Link>


                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Premium</span></li>


                            <Link to="/coupons" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-coupon-line"></i> <span data-key="t-widgets">Coupons</span>
                                </a>
                            </Link>

                            <Link to="/codes" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-coupon-line"></i> <span data-key="t-widgets">Codes</span>
                                </a>
                            </Link>

                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Marketing</span></li>
                            <Link to="/requests" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-exchange-dollar-fill"></i> <span data-key="t-widgets">Requests</span>
                                </a>
                            </Link>

                            <Link to="/partnerships" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class="ri-coupon-line"></i> <span data-key="t-widgets">Partnerships</span>
                                </a>
                            </Link>

                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Support Bot</span></li>
                            <Link to="/triggers" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class=" ri-error-warning-line"></i> <span data-key="t-widgets">Triggers</span>
                                </a>
                            </Link>

                            <Link to="/aisupport" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class=" ri-error-warning-line"></i> <span data-key="t-widgets">Ai Support</span>
                                </a>
                            </Link>

                            <li class="menu-title"><i class="ri-more-fill"></i> <span data-key="t-pages">Errors</span></li>
                            <Link to="/slasherrors" class="nav-item">
                                <a class="nav-link menu-link" href="widgets.html">
                                    <i class=" ri-error-warning-line"></i> <span data-key="t-widgets">Slash Commands</span>
                                </a>
                            </Link>

                            {/* <Link to="/partnerships" class="nav-item">
                                    <a class="nav-link menu-link" href="widgets.html">
                                        <i class="ri-coupon-line"></i> <span data-key="t-widgets">Partnerships</span>
                                    </a>
                                </Link> */}

                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);